import { SvgIconProps } from "../types";

export const SvgIcon = ({ src, width, height }: SvgIconProps) => {
  let url = '';
  if(src.includes("http")){
     url = src;
  } else {
    url = `/img/svg/${src}`
  }
  return (
  <img src={url} alt={src} width={width} height={height} />
)};
